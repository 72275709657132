import { ApolloProvider } from "@apollo/client";
import { Box } from "@mui/material";
import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { ThemeComponent } from "./ThemeComponent";
import client from "./lib/apollo";
import HomePage from "./pages/HomePage";
import MenuPage from "./pages/MenuPage";
const bg = require("./images/bg.jpg");

const App = () => {
  return (
    <ApolloProvider client={client}>
      <ThemeComponent>
        <Box
          className="App"
          sx={{ background: `url("${bg}")`, backgroundRepeat: "repeat" }}
        >
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/menu" element={<MenuPage />} />
            </Routes>
          </BrowserRouter>
        </Box>
      </ThemeComponent>
    </ApolloProvider>
  );
};

export default App;
