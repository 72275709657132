import { Box } from "@mui/material";
import * as React from "react";
import About from "../common/About";
import Contact from "../common/Contact";
import Footer from "../common/Footer";
import Header from "../common/Header";
import Slideshow from "../common/Slideshow";
import SubscribeForm from "../common/SubscribeForm";
import { OrderDiscount } from "../common/OrderDiscount";

const HomePage = () => {
  return (
    <Box display="flex" flexDirection="column">
      <Header />
      <Slideshow />
      <OrderDiscount />
      <SubscribeForm />
      {/* <AboutMark /> */}
      <About />
      <Contact />
      {/* <ChatBox /> */}
      <Footer />
    </Box>
  );
};

export default HomePage;
