import * as React from "react";
import Header from "../common/Header";
import {
  Box,
  Chip,
  Grid,
  styled,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Footer from "../common/Footer";
import { PriceMaker } from "../common/helpers";
import { colors } from "../design/colors";
import { fetchMenu } from "../utils/getMenu";

// const menu = require("../data/menu.json");

// const menuData = fetchMenu();
// console.log("menu", menuData);

// interface IMenuCategoryList {
//   menuData: any;
//   categoryIndex: number;
// }

const MenuPage = () => {
//   const theme = useTheme();
//   const matches = useMediaQuery(theme.breakpoints.up("md"));

//   fetchMenu();

//   return (
//     <Box>
//       <Header hideLanguages={true} />
//       <Box pt={matches ? 10 : 6} pb={8}>
//         {menu.categories.map((cat: any, i: number) => {
//           return <MenuCategoryList menuData={menu} categoryIndex={i} />;
//         })}
//       </Box>
//       <Footer />
//     </Box>
//   );
// };

// const MenuCategoryList = ({ menuData, categoryIndex }: IMenuCategoryList) => {
//   const theme = useTheme();
//   const matches = useMediaQuery(theme.breakpoints.up("md"));

//   return (
//     <Box
//       display="flex"
//       alignSelf="center"
//       pt={1}
//       sx={{
//         backgroundColor: colors.white,
//         maxWidth: "60rem",
//         margin: "0 auto",
//       }}
//     >
//       <Box sx={{ padding: "1rem 2rem", width: "100%" }}>
//         <TextAligner variant={matches ? "h2" : "h4"} sx={{ fontWeight: 600 }}>
//           {menuData.categories[categoryIndex].name}
//         </TextAligner>
//         <TextAligner variant="h6" sx={descriptionCategory}>
//           {menuData.categories[categoryIndex].description}
//         </TextAligner>

//         {menuData.categories[categoryIndex].items.map(
//           (productCategory: any) => {
//             return (
//               <Box
//                 sx={{
//                   padding: "1rem 0",
//                   position: "relative",
//                   width: "100%",
//                   borderTop: "1px solid #CCC",
//                   marginBottom: "0.5rem",
//                 }}
//               >
//                 {productCategory.active === false && (
//                   <DineInOnly
//                     size="small"
//                     label="Dine-in Only"
//                     variant="filled"
//                   />
//                 )}
//                 <TextAligner
//                   variant={matches ? "h5" : "h6"}
//                   sx={{ fontWeight: 600 }}
//                 >
//                   {productCategory.name}
//                 </TextAligner>
//                 <TextAligner variant="body1" sx={descriptionPizza}>
//                   {productCategory.description}
//                 </TextAligner>
//                 <TextAligner variant="body1" display="flex">
//                   <Grid container>
//                     {productCategory.sizes.map(
//                       (size: { price: number; name: string }) => {
//                         return (
//                           <Grid
//                             item
//                             sx={{ pr: "1rem" }}
//                             xs={12}
//                             md="auto"
//                             pb={0.5}
//                           >
//                             <strong>{size.name} </strong>
//                             <PriceMaker
//                               price={productCategory.price}
//                               priceExtra={size.price}
//                             />
//                           </Grid>
//                         );
//                       }
//                     )}
//                     {productCategory.sizes.length === 0 && (
//                       <Grid item xs={12} md={3} pb={0.5}>
//                         <PriceMaker
//                           price={productCategory.price}
//                           priceExtra={null}
//                         />
//                       </Grid>
//                     )}
//                   </Grid>
//                 </TextAligner>
//               </Box>
//             );
//           }
//         )}
//       </Box>
//     </Box>
//   );

return <div></div>
};

export default MenuPage;

// const TextAligner = styled(Typography)(({ theme }) => ({
//   [theme.breakpoints.up("xs")]: {
//     textAlign: "left",
//   },
// }));

// const DineInOnly = styled(Chip)(({ theme }) => ({
//   position: "absolute",
//   background: colors.darkGrey,
//   color: colors.yellow,
//   fontWeight: 600,
//   top: 0,
//   borderTopLeftRadius: 0,
//   borderTopRightRadius: 0,
//   borderBottomLeftRadius: "0.25rem",
//   borderBottomRightRadius: "0.25rem",
//   fontSize: "0.75rem",
//   right: 0,
// }));

// const descriptionCategory: SxProps = {
//   fontWeight: 500,
//   fontSize: { xs: "1.125rem", md: "1.25rem", lg: "1.375rem" },
//   lineHeight: { xs: "1.4rem", md: "1.6rem", lg: "1.8rem" },
//   padding: { xs: "0.5rem 0 2rem", md: "0.5rem 4rem 2rem 0" },
// };

// const descriptionPizza: SxProps = {
//   paddingBottom: "1rem",
//   fontSize: { xs: "1rem", md: "1.125rem" },
// };
