import { Box, styled, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { colors } from "../design/colors";
import { constants } from "../design/const";
import "../i18n";
import { useTranslation } from "react-i18next";
import { useScript } from "../hooks/useScript";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router";

const GloriaFoodButtons = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const status = useScript("https://www.fbgcdn.com/embedder/js/ewm2.js");

  const menuButton: any = useRef();

  useEffect(() => {
    if (status === "ready" && location.hash === "#ordernow") {
      setTimeout(() => {
        menuButton.current.focus();
        menuButton.current.click();
      }, 2000);
    }
  }, [status]);

  return (
    <Box
      sx={{
        margin: "2rem",
        position: "absolute",
        zIndex: 10000,
        bottom: matches ? "-4rem" : "-7.25rem",
        display: "flex",
        flexDirection: {
          xs: "column",
          md: "row",
        },
      }}
    >
      {status === "ready" && (
        <>
          <OrderButton
            data-glf-cuid="e6acdb0f-f970-4c3f-941c-c4ccbf87ddeb"
            data-glf-ruid="11244bd1-21b0-4584-a8bb-163ca63a51ed"
            ref={menuButton}
          >
            {t("button_see_menu")}
          </OrderButton>
          <ReservationButton
            data-glf-cuid="e6acdb0f-f970-4c3f-941c-c4ccbf87ddeb"
            data-glf-ruid="11244bd1-21b0-4584-a8bb-163ca63a51ed"
            data-glf-reservation="true"
          >
            {t("button_reservation")}
          </ReservationButton>
        </>
      )}
    </Box>
  );
};
export default GloriaFoodButtons;

export const ButtonBase = styled("span")(({ theme }) => ({
  fontWeight: 600,
  fontSize: "1.125rem",
  margin: "0 auto 0.5rem auto",
  cursor: "pointer",
  padding: "0.75rem",
  width: "16rem",
  textAlign: "center",
  color: colors.white,
  transition: `all ${constants.speed} ${constants.ease}`,
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  boxShadow: "0 3px 10px rgb(0 0 0 / 0.8)",
  "&:hover": {
    boxShadow: "0 10px 30px rgb(0 0 0 / 0.6)",
  },
  [theme.breakpoints.up("md")]: {
    margin: "0 0.5rem",
  },
}));
const OrderButton = styled(ButtonBase)({
  background: colors.darkRed,
  borderRadius: constants.borderRadius,
  "&:hover": {
    background: colors.red,
  },
});

const ReservationButton = styled(ButtonBase)({
  background: colors.darkYellow,
  borderRadius: constants.borderRadius,
  "&:hover": {
    background: colors.yellow,
  },
});
