import { gql } from "@apollo/client";

export const GET_PROMOTIONS = gql`
  query GetPromotions {
    promotions {
      edges {
        node {
          content
          title
          featuredImage {
            node {
              altText
              mediaDetails {
                sizes {
                  sourceUrl
                  name
                }
              }
            }
          }
          promotion_mobile_image {
            promotionMobileImage {
              mediaDetails {
                sizes {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ABOUT_MARK = gql`
  query GetAboutMark {
    pages(where: { id: 24 }) {
      edges {
        node {
          title
          featuredImage {
            node {
              altText
              mediaDetails {
                sizes {
                  sourceUrl
                  name
                }
              }
            }
          }
          extraDescription {
            description
          }
        }
      }
    }
  }
`;
