import { Box, styled, SxProps } from "@mui/material";
import * as React from "react";
import { colors } from "../design/colors";
import i18n from "../i18n";
const logo = require("../images/logo-color.png");
const usFlag = require("../images/us-flag.png");
const vnFlag = require("../images/vn-flag.png");

interface IHeader {
  sx?: SxProps;
  hideLanguages?: boolean;
}

const Header = ({ sx, hideLanguages }: IHeader) => {
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Box
      display="flex"
      justifyItems="center"
      sx={{
        margin: "0 auto",
        width: "100%",
        height: {
          xs: "7rem",
          md: "8rem",
        },
        position: "relative",
        zIndex: 1000,
        background: colors.black,
        ...sx,
      }}
    >
      <Box
        display="flex"
        justifyItems="center"
        sx={{
          position: "absolute",
          left: {
            xs: 0,
            md: "50%",
          },
          zIndex: 100,
        }}
      >
        <a href="/">
          <Logo src={logo} />
        </a>
      </Box>
      {!hideLanguages && (
        <Box
          gap="0.5rem"
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          flex="1"
          alignItems="center"
          sx={{ padding: "1rem", marginTop: "1.375rem" }}
        >
          <Box sx={{ cursor: "pointer" }}>
            <img
              onClick={() => changeLanguage("en")}
              alt="English"
              src={usFlag}
              width="50"
            />
          </Box>
          <Box sx={{ cursor: "pointer" }}>
            <img
              onClick={() => changeLanguage("vi")}
              alt="Vietnamese"
              src={vnFlag}
              width="50"
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default Header;

const Logo = styled("img")(({ theme }) => ({
  marginLeft: "-125px",
  width: "250px",
  marginTop: "0.5rem",
  paddingTop: "0.5rem",
  paddingBottom: "0.25rem",
  [theme.breakpoints.down("md")]: {
    marginLeft: "2rem",
    width: "200px",
    textAlign: "left",
  },
}));
