import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Box,
  Button,
  Fade,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import * as React from "react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { colors } from "../design/colors";
import { constants } from "../design/const";
import Container from "./Container";
const bg = require("../images/panel-bg.jpg");

type TFormValues = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
};

const SubscribeForm = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<TFormValues>();

  const [subscribed, setSubscribed] = useState(false);
  const [finished, setFinished] = useState(false);

  console.log("subscribe", subscribed, "errors", Object.keys(errors).length);

  const subscribeToCapones = async (data: TFormValues) => {
    var bodyFormData = new FormData();

    bodyFormData.append("customerFirstName", data.firstName);
    bodyFormData.append("customerLastName", data.lastName);
    bodyFormData.append("customerEmail", data.email);
    bodyFormData.append("customerPhone", data.phone);

    axios({
      method: "post",
      url: "https://www.caponessaigon.com/wp-json/contact-form-7/v1/contact-forms/40/feedback",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(() => {
        setSubscribed(true);
        setTimeout(() => {
          setSubscribed(false);
          setTimeout(() => setFinished(true), constants.speedMs);
        }, 4000);
      })

      .catch(function (response) {
        console.log(response);
      });
  };

  const onSubmit = handleSubmit((data) => subscribeToCapones(data));
  const { t } = useTranslation();
  const timeoutSpeeds = { enter: constants.speedMs, exit: constants.speedMs };

  return (
    <Container>
      <Box
        display="flex"
        justifyItems="space-between"
        flexDirection="column"
        sx={{
          // background: colors.black,
          background: `url("${bg}")`,
          backgroundSize: "cover",
          color: colors.white,
          padding: "1rem 2rem 1rem 2rem",
          width: "100%",
          borderRadius: constants.borderRadius,
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              mt: "0.5rem",
              fontWeight: 600,
              textAlign: "center",
              justifyContent: "center",
              fontSize: {
                xs: "1.25rem",
                md: "1.75rem",
              },
              color: colors.white,
            }}
          >
            {t("subscribe_to_us")}
          </Typography>
        </Box>

        <FormStyle name="form" onSubmit={handleSubmit(onSubmit)}>
          <Box
            display="flex"
            flex={1}
            sx={{
              flexDirection: { xs: "column", md: "row" },
              padding: "1rem 0",
              width: "100%",
            }}
          >
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              name="firstName"
              render={({ field }) => (
                <FormInputStyle
                  {...field}
                  label={t("first_name")}
                  id="firstName"
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                />
              )}
            />

            <Controller
              control={control}
              rules={{
                required: true,
              }}
              name="lastName"
              render={({ field }) => (
                <FormInputStyle
                  {...field}
                  label={t("last_name")}
                  id="lastName"
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                />
              )}
            />

            <Controller
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: t("invalid_email"),
                },
              }}
              name="email"
              render={({ field }) => (
                <FormInputStyle
                  {...field}
                  label={t("email")}
                  id="email"
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                />
              )}
            />

            <Controller
              control={control}
              name="phone"
              render={({ field }) => (
                <FormInputStyle
                  {...field}
                  label={t("home_phone")}
                  id="phone"
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                />
              )}
            />

            <ButtonStyle variant="contained" color="primary" type="submit">
              {t("subscribe")}
            </ButtonStyle>
          </Box>

          <Box
            flexDirection="row"
            flexWrap="wrap"
            alignItems="flex-start"
            sx={{
              display:
                Object.keys(errors).length !== 0 || finished === false
                  ? "flex"
                  : "none",
            }}
          >
            <Fade
              in={errors?.firstName?.type === "required"}
              timeout={timeoutSpeeds}
            >
              <ErrorStyle
                sx={{
                  display: errors?.firstName?.type !== "required" && "none",
                }}
              >
                <ErrorOutlineIcon
                  sx={{ fill: colors.darkerRed, pr: "0.25rem" }}
                />
                {t("first_name_required")}
              </ErrorStyle>
            </Fade>

            <Fade
              in={errors?.lastName?.type === "required"}
              timeout={timeoutSpeeds}
            >
              <ErrorStyle
                sx={{
                  display: errors?.lastName?.type !== "required" && "none",
                }}
              >
                <ErrorOutlineIcon
                  sx={{ fill: colors.darkerRed, pr: "0.25rem" }}
                />
                {t("last_name_required")}
              </ErrorStyle>
            </Fade>

            <Fade
              in={errors?.email?.type === "required"}
              timeout={timeoutSpeeds}
            >
              <ErrorStyle
                sx={{ display: errors?.email?.type !== "required" && "none" }}
              >
                <ErrorOutlineIcon
                  sx={{ fill: colors.darkerRed, pr: "0.25rem" }}
                />
                {t("email_required")}
              </ErrorStyle>
            </Fade>

            <Fade in={Boolean(errors?.email?.message)} timeout={timeoutSpeeds}>
              <ErrorStyle
                sx={{
                  display: Boolean(!errors?.email?.message) && "none",
                }}
              >
                <ErrorOutlineIcon
                  sx={{ fill: colors.darkerRed, pr: "0.25rem" }}
                />
                {errors?.email?.message}
              </ErrorStyle>
            </Fade>

            <Fade in={subscribed} timeout={timeoutSpeeds}>
              <SuccessStyle>
                <CheckIcon sx={{ fill: colors.darkGreen, pr: "0.25rem" }} />
                {t("subscribe_success")}
              </SuccessStyle>
            </Fade>
          </Box>
        </FormStyle>
      </Box>
    </Container>
  );
};
export default SubscribeForm;

const FormInputStyle = styled(TextField)(({ theme }) => ({
  margin: "0 0.5rem",
  flex: 1,
  background: colors.darkerRed,
  "& input": { color: colors.white },
  "& label": { color: colors.white },
  "& .MuiInputLabel-shrink": { color: colors.white },
  "& label.Mui-focused": { color: colors.white },
  "&:hover": {
    background: colors.white,
    "& input": { color: colors.black },
    "& label": { color: colors.black },
  },
  "&:first-child": {
    marginLeft: 0,
  },
  [theme.breakpoints.down("md")]: {
    margin: "0 0 0.5rem 0",
  },
  borderRadius: constants.borderRadius,
  transition: `all ${constants.speed} ${constants.ease}`,
}));

const ErrorStyle = styled(Typography)({
  padding: "0.5rem 1rem 0.5rem 0.5rem",
  color: colors.white,
  fontWeight: 600,
  background: colors.lightRed,
  alignItems: "center",
  textAlign: "left",
  display: "flex",
  marginBottom: "0.5rem",
  borderRadius: constants.borderRadius,
  "&:not(first-child)": {
    marginRight: "0.5rem",
  },
});

const SuccessStyle = styled(Typography)({
  padding: "0.5rem 1rem 0.5rem 0.5rem",
  color: colors.white,
  fontWeight: 600,
  background: colors.lightGreen,
  alignItems: "center",
  textAlign: "left",
  display: "flex",
  borderRadius: constants.borderRadius,
  "&:not(first-child)": {
    marginRight: "0.5rem",
  },
});

const FormStyle = styled("form")({
  textAlign: "left",
  flex: 1,
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const ButtonStyle = styled(Button)(({ theme }) => ({
  pl: "2rem",
  pr: "2rem",
  fontWeight: 600,
  minHeight: "56px",
  borderRadius: constants.borderRadius,
  background: colors.darkRed,
  "&:hover": { background: colors.red },
  [theme.breakpoints.up("md")]: {
    marginLeft: "0.5rem",
  },
}));
