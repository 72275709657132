import { useTheme, useMediaQuery, Typography } from "@mui/material";
import * as React from "react"

export const Heading = ({children}:any)=> {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    return <Typography
        variant={matches ? 'h4' : 'h5'}
        align="left"
        sx={{marginTop: '1rem', fontWeight: 600, marginBottom: '1rem'}}>
        {children}
    </Typography>
}