import { Box, Typography } from "@mui/material";
import * as React from "react";
import "../i18n";
import { colors } from "../design/colors";
import { useTranslation } from "react-i18next";
import Container from "./Container";
import { Heading } from "./components";
import { constants } from "../design/const";

const About = () => {
  const { i18n, t } = useTranslation();
  const lang = i18n.language;
  return (
    <Container>
      <Box
        display="flex"
        justifyItems="space-between"
        flexDirection="column"
        sx={{
          background: colors.darkBrown,
          color: colors.lightGrey,
          borderRadius: constants.borderRadius,
          padding: "2rem",
        }}
      >
        <Heading>{t("home_about_us_title")}</Heading>
        <Typography variant="body1" align="left">
          {lang === "en"
            ? `Capone's offers Authentic Chicago Deep Dish pizza and street food in the heart of Saigon! We have both traditional deep dish and thin crust pizzas. Every month we feature a Pizza of the Month. We also have chicken wings, pasta, foldover pizzas, hot dogs, zacos (pizza tacos), mozzarella sticks and more.`
            : "Capone’s cho bạn trải nghiệm về pizza và thức ăn đường phố theo phong cách Chicago chính thống ngay giữa lòng Sài Gòn! Chúng tôi có đế Pizza truyền thống và đế mỏng cho các bạn lựa chọn. Hàng tháng những loại bánh pizza đặc biệt sẽ được ra mắt. Chúng tôi cũng có cánh gà, mì ống, pizza gấp, xúc xích, zacos (pizza tacos), phô mai que mozzarella và nhiều hơn thế nữa."}
        </Typography>
      </Box>
    </Container>
  );
};
export default About;
