import { Box } from "@mui/material";
import * as React from "react";

const Container = (props: any) => {
  return (
    <Box
      display="flex"
      justifyItems="space-between"
      sx={{
        maxWidth: "1024px",
        margin: "0 auto",
        padding: props.padding ? props.padding : "1rem",
        background: props.background ? props.background : "transparent",
        width: "calc(100% - 2rem)",
        flexGrow: 1,
      }}
    >
      {props.children}
    </Box>
  );
};
export default Container;
