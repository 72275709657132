import {
  Box,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import * as React from "react";
import { useRef } from "react";
import { colors } from "../design/colors";
import { constants } from "../design/const";
import { useScript } from "../hooks/useScript";
import i18n from "../i18n";
import Container from "./Container";
import { ButtonBase } from "./GloriaFoodButtons";
import { useTranslation } from "react-i18next";
const bg = require("../images/sky.jpg");
const hotdogBg = require("../images/hotdog-bg.jpg");

export const OrderDiscount = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const menuButton: any = useRef();
  const status = useScript("https://www.fbgcdn.com/embedder/js/ewm2.js");
  const { i18n } = useTranslation();
  const lang = i18n.language;

  return (
    <Container padding={matches ? "3rem 1rem 0 1rem" : "4rem 1rem 0 1rem"}>
      <Box
        sx={{
          background: `url(${hotdogBg})`,
          //   backgroundColor: colors.darkBrown,
          backgroundSize: "cover",
          backgroundPosition: "left center",
          display: "flex",
          width: "100%",
          height: "100%",
          padding: matches ? "2rem 5rem" : "2rem",
          textAlign: matches ? "left" : "center",
          flexDirection: matches ? "row" : "column",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {lang === "en" ? (
          <div>
            <Typography variant="h4" color={colors.darkYellow}>
              <strong style={{ color: colors.white }}>GET 15% OFF</strong>
              <br />
              by ordering on our website
            </Typography>
            <Typography
              variant="body1"
              sx={{ paddingTop: "0.5rem" }}
              color={colors.darkYellow}
            >
              * 250,000₫ minimum order
            </Typography>
          </div>
        ) : (
          <Box sx={{ width: matches ? "70%" : "100%" }}>
            <Typography variant="h4" color={colors.darkYellow}>
              <strong style={{ color: colors.white }}>NHẬN GIẢM GIÁ 15%</strong>
              <br />
              Bằng cách đặt hàng trên trang web của chúng tôi
            </Typography>
            <Typography
              variant="body1"
              sx={{ paddingTop: "0.5rem" }}
              color={colors.darkYellow}
            >
              * Đơn hàng tối thiểu 250.000₫
            </Typography>
          </Box>
        )}

        <Box sx={{ marginTop: matches ? 0 : "2rem" }}>
          {status === "ready" && (
            <OrderButton
              data-glf-cuid="e6acdb0f-f970-4c3f-941c-c4ccbf87ddeb"
              data-glf-ruid="11244bd1-21b0-4584-a8bb-163ca63a51ed"
              ref={menuButton}
            >
              {lang === "en" ? "Order Now" : "Đặt hàng ngay"}
            </OrderButton>
          )}
        </Box>
      </Box>
    </Container>
  );
};

const OrderButton = styled(ButtonBase)({
  background: colors.darkRed,
  borderRadius: constants.borderRadius,
  padding: "0.5rem 2rem",
  "&:hover": {
    background: colors.red,
  },
});
