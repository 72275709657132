import { Box, Typography } from "@mui/material";
import * as React from "react";
import "../i18n";
import { colors } from "../design/colors";
import { useTranslation } from "react-i18next";
const year = new Date().getFullYear();

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      justifyItems="space-between"
      sx={{
        background: colors.black,
        padding: "1rem",
      }}
    >
      <Typography variant="body1" sx={{ color: colors.white }}>
        {t("footer_about")} &copy; {year}
      </Typography>
    </Box>
  );
};
export default Footer;
