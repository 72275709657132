import * as React from "react";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import { ThemeProvider as JSSThemeProvider } from "@mui/styles";
import { FC } from "react";
import { colors } from "./design/colors";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  palette: {
    primary: {
      main: colors.black,
    },
    secondary: {
      main: colors.red,
    },
  },
});

export const ThemeComponent: FC<any> = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <JSSThemeProvider theme={theme}>{children}</JSSThemeProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
