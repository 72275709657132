export const colors = {
  white: "#FFFFFF",
  black: "#000",
  offBlack: "#111",
  red: "#ea2424",
  darkRed: "#ab2020",
  darkGreen: "#003808",
  darkerRed: "#430c0c",
  darkestRed: "#240606",
  yellow: "#ffc800",
  darkYellow: "#e38600",
  brown: "#281508",
  darkBrown: "#140802",
  orange: "#a23315",
  lightBlue: "#bcdbf0",
  darkBlue: "#223281",
  blue: "#1e3dd2",
  lightGrey: "#EEEEEE",
  darkGrey: "#222",
  lightRed: "rgba(200,0,0,0.7)",
  lightGreen: "rgba(0,150,0,0.7)",
  tiktokPink: "#ff0050",
  facebook: "#3b5998",
  logoGreen: "#5CE1E6",
  logoOrange: "#C45A35",
};
