import { Box, styled, SvgIcon, Typography } from "@mui/material";
import * as React from "react";
import "../i18n";
import { colors } from "../design/colors";
import { useTranslation } from "react-i18next";
import Container from "./Container";
import { constants } from "../design/const";
import { Heading } from "./components";

const FBIcon = () => {
  return (
    <SvgIcon
      sx={{ width: "64px", height: "64px", color: colors.facebook }}
      viewBox="0 0 32 32"
    >
      <path d="M29 0h-26c-1.65 0-3 1.35-3 3v26c0 1.65 1.35 3 3 3h13v-14h-4v-4h4v-2c0-3.306 2.694-6 6-6h4v4h-4c-1.1 0-2 0.9-2 2v2h6l-1 4h-5v14h9c1.65 0 3-1.35 3-3v-26c0-1.65-1.35-3-3-3z"></path>
    </SvgIcon>
  );
};

const TiktokIcon = () => {
  return (
    <SvgIcon
      sx={{ width: "64px", height: "64px", color: colors.tiktokPink }}
      viewBox="0 0 512 512"
    >
      <path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z" />
    </SvgIcon>
  );
};

const Link = styled("a")({
  color: colors.white,
  textDecoration: "none",
  transition: `all ${constants.speed} ${constants.ease}`,
  "&:hover": {
    color: colors.darkRed,
  },
});

const Contact = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Box
        display="flex"
        justifyItems="space-between"
        flexDirection="column"
        sx={{
          background: colors.darkBrown,
          color: colors.lightGrey,
          padding: "2rem",
          width: "100%",
          borderRadius: constants.borderRadius,
        }}
      >
        <Heading>{t("home_contact_us_title")}</Heading>

        <Box
          display="flex"
          gap="2rem"
          sx={{
            flexDirection: {
              xs: "column",
              md: "row",
            },
            width: "100%",
          }}
        >
          <Box>
            <Box sx={{ paddingBottom: "1rem" }}>
              <Typography
                variant="body1"
                align="left"
                sx={{ fontWeight: 600, marginBottom: "0.5rem" }}
              >
                {t("home_address")}
              </Typography>
              <Typography variant="body1" align="left">
                8/3 Đường số 49B, Thảo Điền, Quận 2, Thành phố Hồ Chí Minh
              </Typography>
            </Box>
            <Box sx={{ paddingBottom: "1rem" }}>
              <Typography
                variant="body1"
                align="left"
                sx={{ fontWeight: 600, marginBottom: "0.5rem" }}
              >
                {t("home_phone")}
              </Typography>
              <Typography variant="body1" align="left">
                <Link href="tel://+84338787459">+84 33 878 7459</Link>
              </Typography>
            </Box>
            <Box sx={{ paddingBottom: "1rem" }}>
              <Typography
                variant="body1"
                align="left"
                sx={{ fontWeight: 600, marginBottom: "0.5rem" }}
              >
                {t("home_email")}
              </Typography>
              <Typography variant="body1" align="left">
                <Link href="mailto:caponessaigon@gmail.com">
                  caponessaigon@gmail.com
                </Link>
              </Typography>
            </Box>
            <Box sx={{ paddingBottom: "1rem" }}>
              <Typography
                variant="body1"
                align="left"
                sx={{ fontWeight: 600, marginBottom: "0.5rem" }}
              >
                {t("home_social")}
              </Typography>
              <Typography variant="body1" align="left">
                <Box display="flex" gap="1rem">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/CaponesDeepDishandStreetFood"
                  >
                    <FBIcon />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.tiktok.com/@caponessaigon?lang=en"
                  >
                    <TiktokIcon />
                  </a>
                </Box>
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            flex="1"
            sx={{ paddingBottom: "2rem" }}
            flexDirection="column"
          >
            <Typography
              variant="body1"
              align="left"
              sx={{ fontWeight: 600, marginBottom: "0.5rem" }}
            >
              {t("home_hours")}
            </Typography>
            <Typography variant="body1" align="left">
              <Box flexDirection="column" display="flex" sx={{ width: "100%" }}>
                <Box display="flex" flexDirection="row">
                  <Box flex="1" sx={{ paddingRight: "1rem" }}>
                    Monday
                  </Box>
                  <Box flex="3" textAlign="right">
                    11:00 - 23:00
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row">
                  <Box flex="1" sx={{ paddingRight: "1rem" }}>
                    Tuesday
                  </Box>
                  <Box flex="3" textAlign="right">
                    11:00 - 23:00
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row">
                  <Box flex="1" sx={{ paddingRight: "1rem" }}>
                    Wednesday
                  </Box>
                  <Box flex="3" textAlign="right">
                    11:00 - 23:00
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row">
                  <Box flex="1" sx={{ paddingRight: "1rem" }}>
                    Thursday
                  </Box>
                  <Box flex="3" textAlign="right">
                    11:00 - 23:00
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row">
                  <Box flex="1" sx={{ paddingRight: "1rem" }}>
                    Friday
                  </Box>
                  <Box flex="3" textAlign="right">
                    11:00 - 23:00
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row">
                  <Box flex="1" sx={{ paddingRight: "1rem" }}>
                    Saturday
                  </Box>
                  <Box flex="3" textAlign="right">
                    11:00 - 23:00
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row">
                  <Box flex="1" sx={{ paddingRight: "1rem" }}>
                    Sunday
                  </Box>
                  <Box flex="3" textAlign="right">
                    11:00 - 23:00
                  </Box>
                </Box>
              </Box>
            </Typography>
          </Box>
        </Box>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.1241372033005!2d106.72653295055538!3d10.80180326164026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3175272a71020635%3A0xa5e6d739a2483f9!2sCapone%E2%80%99s%20Chicago%20Deep%20Dish%20Pizza%20%26%20Street%20Food!5e0!3m2!1sen!2s!4v1666183541625!5m2!1sen!2s"
          width="100%"
          height="300"
          style={{
            border: `4px solid ${colors.black}`,
            borderRadius: constants.borderRadius,
          }}
          loading="lazy"
        ></iframe>
      </Box>
    </Container>
  );
};
export default Contact;
